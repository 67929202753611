// client/src/components/About.js
import React from 'react';
import { Container } from 'react-bootstrap';

function About() {
  return (
    <Container className="mt-5">
      <h2>О формате HEIC</h2>
      <p>
        Формат HEIC (High Efficiency Image File Format) — это современный формат изображения, который обеспечивает
        высокое качество при меньшем размере файла по сравнению с традиционными форматами вроде JPEG. Он был разработан
        для более эффективного хранения фотографий на устройствах с ограниченным объёмом памяти.
      </p>

      <h2>Зачем конвертировать HEIC в JPG?</h2>
      <p>
        Несмотря на преимущества HEIC, не все устройства и приложения поддерживают этот формат. Конвертация HEIC в
        широко распространённый формат JPG позволяет легко просматривать и редактировать ваши фотографии на любых
        устройствах и в любых программах.
      </p>

      <h2>Преимущества нашего конвертера</h2>
      <ul>
        <li>Быстрая и безопасная конвертация ваших изображений.</li>
        <li>Полностью бесплатный сервис без скрытых платежей и ограничений.</li>
        <li>Не требуется установка дополнительного программного обеспечения.</li>
        <li>Поддержка файлов размером до 20 МБ.</li>
        <li>Автоматическое удаление файлов через 2 минуты для защиты вашей конфиденциальности.</li>
      </ul>

      <h2>Как конвертировать HEIC в JPG?</h2>
      <ol>
        <li>Нажмите на кнопку "Выберите файл" и загрузите ваше HEIC-изображение.</li>
        <li>Нажмите на кнопку "Конвертировать" и дождитесь завершения процесса.</li>
        <li>После конвертации нажмите "Скачать результат" для сохранения JPG-изображения.</li>
      </ol>

      <h2>Безопасность и конфиденциальность</h2>
      <p>
        Мы уважаем вашу конфиденциальность. Все загруженные изображения удаляются с нашего сервера автоматически через 2
        минуты после конвертации. Мы не сохраняем и не передаём ваши файлы третьим лицам.
      </p>
    </Container>
  );
}

export default About;
