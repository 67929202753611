// client/src/App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  ProgressBar,
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import About from './components/About';
import AdBlock from './components/AdBlock';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function Home() {
  const [files, setFiles] = useState([]);

  const uploadFiles = async (e) => {
    e.preventDefault();
    const selectedFiles = Array.from(e.target.elements.file.files).slice(0, 5); // Максимум 5 файлов

    const newFiles = selectedFiles.map((file) => ({
      id: `${file.name}-${Date.now()}`,
      file,
      name: file.name,
      progress: 0,
      status: 'uploading', // 'uploading' | 'done' | 'error'
      downloadUrl: '',
      error: '',
      timeLeft: null,
    }));

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);

    newFiles.forEach((fileObj) => {
      const formData = new FormData();
      formData.append('heicFile', fileObj.file);

      axios.post('/api/convert', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.id === fileObj.id ? { ...f, progress: percentCompleted } : f
            )
          );
        },
      })
        .then((res) => {
          const jobId = res.data.jobId;

          // Начинаем опрос статуса задачи каждые 2 секунды
          const interval = setInterval(async () => {
            try {
              const statusRes = await axios.get(`/api/status/${jobId}`);
              const { state, progress } = statusRes.data;

              if (state === 'completed') {
                const absoluteDownloadUrl = `/api/download/${jobId}`;
                setFiles((prevFiles) =>
                  prevFiles.map((f) =>
                    f.id === fileObj.id
                      ? { ...f, status: 'done', progress: 100, downloadUrl: absoluteDownloadUrl, timeLeft: 120 }
                      : f
                  )
                );
                toast.success(`Файл "${fileObj.name}" успешно конвертирован!`);
                clearInterval(interval);
              } else if (state === 'failed') {
                setFiles((prevFiles) =>
                  prevFiles.map((f) =>
                    f.id === fileObj.id
                      ? { ...f, status: 'error', error: 'Ошибка при конвертации файла.', progress: 0 }
                      : f
                  )
                );
                toast.error(`Ошибка при конвертации файла "${fileObj.name}".`);
                clearInterval(interval);
              } else {
                setFiles((prevFiles) =>
                  prevFiles.map((f) =>
                    f.id === fileObj.id ? { ...f, progress } : f
                  )
                );
              }
            } catch (error) {
              console.error('Ошибка при получении статуса задачи:', error);
              toast.error(`Ошибка при получении статуса задачи для файла "${fileObj.name}".`);
              clearInterval(interval);
            }
          }, 2000); // Период опроса: 2 секунды
        })
        .catch((err) => {
          setFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.id === fileObj.id
                ? { ...f, status: 'error', error: err.response?.data?.error || 'Ошибка при загрузке файла.', progress: 0 }
                : f
            )
          );
          toast.error(`Ошибка при загрузке файла "${fileObj.name}".`);
        });
    });

    // Очистка формы после выбора файлов
    e.target.reset();
  };

  // Общий таймер для всех файлов
  useEffect(() => {
    const timer = setInterval(() => {
      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.timeLeft > 0 ? { ...f, timeLeft: f.timeLeft - 1 } : f
        )
      );
    }, 1000); // Каждую секунду

    return () => clearInterval(timer);
  }, []);

  // Обработка событий при достижении timeLeft === 0
  useEffect(() => {
    files.forEach((fileObj) => {
      if (fileObj.timeLeft === 0 && fileObj.status === 'done') {
        toast.info(`Файл "${fileObj.name}" был удалён с сервера.`);
        setFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.id === fileObj.id
              ? { ...f, downloadUrl: '', timeLeft: null }
              : f
          )
        );
      }
    });
  }, [files]);

  return (
    <div className="main-container">
      {/* Левая колонка для рекламы */}
      <div className="advertisement-column ad-left">
        <AdBlock blockId="R-A-12115553-1" renderTo="yandex_rtb_R-A-12115553-1" />
      </div>

      {/* Центральная колонка с конвертером */}
      <div className="main-content">
        <Container>
          <h1 className="text-center mt-4">Converts today</h1>
          <p className="text-center">
            Бесплатный онлайн-конвертер для преобразования изображений HEIC в формат JPG.
          </p>

          <Form onSubmit={uploadFiles} className="mt-4 converter-form">
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Выберите до 5 HEIC файлов для конвертации</Form.Label>
              <Form.Control
                type="file"
                accept=".heic,.heif"
                multiple
                name="file"
                required
              />
            </Form.Group>
            <div className="d-grid gap-2">
              <Button variant="primary" type="submit" disabled={files.length >= 5}>
                Конвертировать
              </Button>
            </div>
          </Form>

          {/* Список загружаемых файлов */}
          {files.map((fileObj) => (
            <div key={fileObj.id} className="file-item mt-3 p-3 border rounded">
              <Row className="align-items-center">
                <Col md={4}>
                  <strong>{fileObj.name}</strong>
                </Col>
                <Col md={4}>
                  {fileObj.status === 'uploading' && (
                    <ProgressBar
                      now={fileObj.progress}
                      label={`${fileObj.progress}%`}
                      animated
                      striped
                    />
                  )}
                  {fileObj.status === 'done' && fileObj.downloadUrl && (
                    // Кнопка для скачивания
                    <Button
                      variant="success"
                      onClick={() => window.location.href = fileObj.downloadUrl}
                      className="w-100"
                    >
                      Скачать изображение
                    </Button>
                  )}
                  {fileObj.status === 'done' && !fileObj.downloadUrl && (
                    // Надпись "Удалено" красного цвета
                    <div className="text-danger text-center fw-bold">
                      Удалено
                    </div>
                  )}
                  {fileObj.status === 'error' && (
                    <Alert variant="danger" className="p-1 m-0">
                      {fileObj.error}
                    </Alert>
                  )}
                </Col>
                <Col md={4}>
                  {fileObj.timeLeft > 0 && (
                    <>
                      <div className="timer-label">Файл будет удалён через:</div>
                      <Alert variant="warning" className="p-1 m-0 text-center">
                        {fileObj.timeLeft} сек
                      </Alert>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          ))}
        </Container>
      </div>

      {/* Правая колонка для рекламы */}
      <div className="advertisement-column ad-right">
        <AdBlock blockId="R-A-12115553-3" renderTo="yandex_rtb_R-A-12115553-3" />
      </div>

      {/* Мобильный рекламный блок */}
      <div className="mobile-only">
        <AdBlock
          blockId="R-A-12115553-2"
          renderTo="yandex_rtb_R-A-12115553-2"
          type="fullscreen"
          platform="touch"
        />
      </div>

      <ToastContainer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Container>
          <Link className="navbar-brand" to="/">Converts today</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/">Главная</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">О нас</Link>
              </li>
            </ul>
          </div>
        </Container>
      </nav>

      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about">
          <About />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
