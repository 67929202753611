// client/src/components/AdBlock.js
import React, { useEffect } from 'react';

const AdBlock = ({ blockId, renderTo, type, platform }) => {
  useEffect(() => {
    window.yaContextCb = window.yaContextCb || [];
    window.yaContextCb.push(() => {
      if (window.Ya && window.Ya.Context && window.Ya.Context.AdvManager) {
        const params = {
          blockId,
          renderTo
        };
        if (type) params.type = type;
        if (platform) params.platform = platform;
        window.Ya.Context.AdvManager.render(params);
      }
    });
  }, [blockId, renderTo, type, platform]);

  return (
    <div id={renderTo} style={{ width: '100%', height: '100%' }}></div>
  );
};

export default AdBlock;
